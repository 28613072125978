import { faArrowUp, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BBox } from 'geojson'
import GoogleMapReact from 'google-map-react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useSupercluster from 'use-supercluster'
import { AutocompleteResult, Spot } from '../../../backend/src/gusty'
import * as api from '../api'
import * as utils from '../utils'
import { MapSettings } from './App'
import { SpotModal } from './SpotModal'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
}))

type MarkerProps = {
  spot: Spot
  lat: number
  lng: number
  onClick: () => void
}

function Marker({ spot, onClick }: MarkerProps) {
  const color = utils.getColorForWindSpeed(spot.wind.average)
  return (
    <span style={{ fontSize: 15, color, width: 100, display: 'inline-block' }} onClick={onClick}>
      <FontAwesomeIcon
        icon={faLocationArrow}
        style={{ transform: `rotate(${spot.wind.direction - 180 - 45}deg` }}
      />
      <span style={{ marginLeft: 4 }}>{Math.round(spot.wind.average).toFixed(0)}</span>
    </span>
  )
}

type OverlayProps = {
  spot: Spot
  lat: number
  lng: number
  allowModal: boolean
  onDetails: () => void
  onClose: () => void
}

function Overlay({ spot, onDetails, onClose, allowModal }: OverlayProps) {
  return (
    <Card style={{ width: 250, marginLeft: -125, marginTop: 25 }}>
      <CardContent>
        <Typography variant="h6">{spot.name}</Typography>
        <Typography variant="body1">
          <span>
            Avg {utils.fixedSpeed(spot.wind.average)}{' '}
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{ transform: `rotate(${spot.wind.direction - 180}deg` }}
            />
          </span>
          <span style={{ marginLeft: 16 }}>
            Lull/Gust {utils.fixedSpeed(spot.wind.lull)}/{utils.fixedSpeed(spot.wind.gust)}
          </span>
          <br />
        </Typography>
      </CardContent>
      {allowModal && (
        <CardActions>
          <Button onClick={() => onClose()}>Close</Button>{' '}
          <Button color="primary" onClick={() => onDetails()}>
            Details
          </Button>{' '}
        </CardActions>
      )}
    </Card>
  )
}

type SpotsMapProps = {
  mapSettings: MapSettings
  height: number
  allowModal?: boolean
}

type Geometry = {
  type: 'Feature'
  properties: {
    cluster: boolean
    spot: Spot
  }
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export default function SpotsMap({
  mapSettings,
  height,
  allowModal = true,
}: SpotsMapProps): JSX.Element {
  const classes = useStyles()
  const [bounds, setBounds] = useState<BBox | undefined>(undefined)
  const [zoom, setZoom] = useState(mapSettings.googleMapsZoom)
  const [spots, setSpots] = useState<Spot[]>([])
  const token = useSelector((state) => state.token)
  const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const points: Geometry[] = spots.map((spot) => ({
    type: 'Feature',
    properties: { cluster: false, spot },
    geometry: {
      type: 'Point',
      coordinates: [spot.coordinates.lng, spot.coordinates.lat],
    },
  }))

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 40, maxZoom: 20 },
  })

  useEffect(() => {
    if (!token || !bounds) {
      return
    }
    api
      .getSpots({ bounds, zoom, token })
      .then(setSpots)
      .catch((err) => console.error(err))
  }, [bounds, zoom, token])

  const modalSpot: AutocompleteResult | null = selectedSpot && {
    type: 'station',
    id: selectedSpot.id.toFixed(0),
    name: selectedSpot.name,
    coordinates: selectedSpot.coordinates,
  }

  return (
    <div className={classes.mapContainer} style={{ height }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAw_-3sGEo8y_fTtr5_mnZgMEXZTLOBuXI' }}
        defaultCenter={mapSettings.coordinates}
        defaultZoom={mapSettings.googleMapsZoom}
        options={{
          mapTypeId: 'satellite',
          disableDefaultUI: true,
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom)
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
        }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {clusters.map((cluster) => {
          if (cluster.properties.cluster) {
            return null
          }

          const spot = cluster.properties.spot

          return (
            <Marker
              key={spot.id}
              spot={spot}
              lat={spot.coordinates.lat}
              lng={spot.coordinates.lng}
              onClick={() => setSelectedSpot(spot)}
            />
          )
        })}

        {selectedSpot && (
          <Overlay
            spot={selectedSpot}
            key={`overlay-${selectedSpot.id}`}
            lat={selectedSpot.coordinates.lat}
            lng={selectedSpot.coordinates.lng}
            onDetails={() => setIsModalOpen(true)}
            onClose={() => {
              setSelectedSpot(null)
              setIsModalOpen(false)
            }}
            allowModal={allowModal}
          />
        )}
      </GoogleMapReact>
      {allowModal && (
        <SpotModal
          result={modalSpot ?? undefined}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}
