import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import { DailyForecastSpot, DailyForecastSpotPoint } from '../../../backend/src/gusty'
import * as utils from '../utils'

const useStyles = makeStyles((theme) => ({
  forecastContainer: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing(1.5),
    },
  },
  forecastTimeframe: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  forecastTimeframeRibbon: {
    marginTop: theme.spacing(0),
    height: 6,
  },
  forecastTimeframeTitle: {
    fontWeight: 'bold !important',
  },
  forecastTimeframeDetails: {
    marginTop: theme.spacing(0.5),
  },
}))

export default function SpotForecast({ forecast }: { forecast: DailyForecastSpot }): JSX.Element {
  const classes = useStyles()

  // Dedupe intervals by direction & speed so they can be combined horizontally
  const cloned = _.cloneDeep(forecast.forecast)
  const deduped: DailyForecastSpotPoint[] = []
  for (const point of cloned) {
    const last = _.last(deduped)
    if (!last) {
      deduped.push(point)
      continue
    }
    if (
      last.direction === point.direction &&
      last.minSpeed === point.minSpeed &&
      last.maxSpeed === point.maxSpeed
    ) {
      last.intervals.push(...point.intervals)
      continue
    }
    deduped.push(point)
  }

  const timeframes = _.map(deduped, (point, pointIndex) => {
    const title = _.first(point.intervals) ?? ''
    const color = utils.getColorForWindSpeed(point.maxSpeed)
    return (
      <div className={classes.forecastTimeframe} key={`timeframe-${pointIndex}`}>
        <Typography variant="caption" className={classes.forecastTimeframeTitle}>
          {title}
        </Typography>
        <div className={classes.forecastTimeframeRibbon} style={{ backgroundColor: color }}></div>
        <Typography variant="caption" className={classes.forecastTimeframeDetails}>
          {point.minSpeed} - {point.maxSpeed}
          <br />
          {point.direction}
        </Typography>
      </div>
    )
  })

  return <div className={classes.forecastContainer}>{timeframes}</div>
}
