import { Box, Chip, Skeleton, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import { useCallback } from 'react'
import { getColorForWindStrength, strength } from '../utils'
import { AiForecast } from '../../../backend/src/gusty'
import { Area } from './App'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { DateTime } from 'luxon'

type AiForecastProps = {
  area: Area
  forecast: AiForecast | null
  isLoading: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  update: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updateText: {
    marginLeft: `${theme.spacing(1)} !important`,
    flexShrink: 0,
  },
  chips: {
    // marginTop: theme.spacing(1),
  },
}))

export function AiForecastOverview({ forecast, area, isLoading }: AiForecastProps) {
  const classes = useStyles()

  const chipsForDay = useCallback(
    (spots: AiForecast['days']) => {
      const orderedSpots = _.orderBy(spots, (spot) => strength[spot.rating].speeds[0], 'desc')

      return _.map(orderedSpots, (spot) => {
        const backgroundColor = getColorForWindStrength(spot.rating)

        let spotName: string
        const spotFromArea = area.spots.find((s) => s.spotId === spot.spotId)
        if (spotFromArea) {
          spotName = spotFromArea.title
        } else {
          spotName = spot.spotName
        }

        return (
          <Tooltip
            title={spot.explanation}
            placement="top"
            enterTouchDelay={0}
            arrow
            key={`${spot.spotId}-${spot.dayOfTheWeek}`}
          >
            <Chip
              key={spot.spotId}
              size="small"
              label={<span style={{ fontWeight: 400 }}>{spotName}</span>}
              icon={<Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor }} />}
              variant="outlined"
              sx={{ marginLeft: 1, marginBottom: 1, paddingLeft: 0.3 }}
            />
          </Tooltip>
        )
      })
    },
    [area.spots]
  )

  const tmp = _.groupBy(forecast?.days ?? [], (day) => day.dayOfTheWeek)
  const days = Object.values(tmp)

  if (!isLoading && !forecast) {
    return null
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6">
        <AutoAwesomeIcon sx={{ fontSize: 16, marginRight: 1 }} />
        Forecast Summary
      </Typography>
      {forecast && (
        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
          Updated{' '}
          {DateTime.fromISO(forecast.createdAt)
            .setZone(forecast.timeZone)
            .toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      )}
      <Box sx={{ marginBottom: 2 }} />
      {isLoading &&
        _.times(3, (dayIndex) => (
          <Box display="flex" flexDirection="row" key={dayIndex}>
            <Skeleton variant="text" sx={{ width: 80, height: 24, marginRight: 2 }} />
            <Skeleton variant="text" sx={{ width: 140, height: 24, marginRight: 1 }} />
            <Skeleton variant="text" sx={{ width: 140, height: 24, marginRight: 1 }} />
            <Skeleton variant="text" sx={{ width: 140, height: 24, marginRight: 1 }} />
          </Box>
        ))}
      {forecast &&
        days.map((day) => (
          <Box display="flex" flexDirection="row" key={day[0].dayOfTheWeek}>
            <Typography
              variant="body2"
              component="div"
              color="textSecondary"
              style={{ width: 70, flexShrink: 0 }}
            >
              <span>{_.upperFirst(day[0].dayOfTheWeek)}</span>
            </Typography>
            <div className={classes.chips}>{chipsForDay(day)}</div>
          </Box>
        ))}
    </div>
  )
}
