import { Area } from '../components/App'

export const southTexas: Area = {
  key: 'southTexas',
  name: 'South Texas',
  mapSettings: {
    coordinates: {
      lat: 27.6935475,
      lng: -97.2913025,
    },
    zoom: 20,
    googleMapsZoom: 10,
  },
  forecastId: '94',
  spots: [
    {
      title: 'Corpus Christi NAS',
      spotId: 1121,
    },
    {
      spotId: 170311,
      title: 'Aransas Pass',
    },
    {
      spotId: 1525,
      title: 'Wildcat',
    },
    {
      spotId: 1406,
      title: 'Poenisch Park',
    },
    {
      spotId: 538,
      title: 'Laguna Shores',
    },
    {
      spotId: 26195,
      title: 'South Bird Island',
    },
    {
      spotId: 83145,
      title: 'The Flats/SPIW Park',
    },
    {
      spotId: 170310,
      title: 'Jetties-Brazos Pass',
    },
  ],
}
