import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Fragment } from 'react'
import { ExtendedForecast as ExtendedForecastType } from '../../../backend/src/gusty'

type ExtendedForecastProps = {
  forecast: ExtendedForecastType
}

export default function ExtendedForecast({ forecast }: ExtendedForecastProps): JSX.Element {
  const formattedCreatedAt = DateTime.fromISO(forecast.createdAt)
    .setZone(forecast.timeZone)
    .toLocaleString(DateTime.DATETIME_MED)
  const lines = _.map(forecast.days, (day) => {
    return (
      <Fragment key={day.day}>
        <Typography variant="h5" style={{ marginTop: 8 }}>
          {DateTime.fromISO(day.day).setZone(forecast.timeZone).toFormat('cccc')}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: 8 }}
          dangerouslySetInnerHTML={{ __html: day.details }}
        ></Typography>
      </Fragment>
    )
  })

  return (
    <Grid item xs={12} md={6} style={{ marginTop: '50px' }}>
      <section>
        <Typography variant="h4">Extended Forecast</Typography>
        <Typography variant="subtitle1" style={{ marginTop: 8 }}>
          <i>{formattedCreatedAt}</i>
        </Typography>
        <Typography variant="h6">{forecast.title}</Typography>
        {lines}
      </section>
    </Grid>
  )
}
