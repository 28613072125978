import { Area } from '../components/App'

export const bayArea: Area = {
  key: 'bayArea',
  name: 'Bay Area',
  mapSettings: {
    coordinates: {
      lat: 37.75959,
      lng: -122.334019,
    },
    zoom: 20,
    googleMapsZoom: 9.5,
  },
  forecastId: '2',
  spots: [
    {
      title: 'Sherman Island',
      spotId: 1786,
      offshore: { start: 25, end: 185 },
    },
    {
      title: 'Waddell',
      spotId: 410,
    },
    {
      title: '3rd Avenue',
      spotId: 1374,
      offshore: { start: 100, end: 260 },
    },
    {
      title: 'Alameda',
      spotId: 416,
      offshore: { start: 305, end: 105 },
      liveWindImageUrl:
        'https://boardsportscalifornia.com/boardsportscalifornia.com/windreport.png',
      webcamUrl: 'https://boardsportscalifornia.com/boardsportscalifornia.com/alamedacam.jpg',
    },
    {
      title: 'Toll Plaza',
      spotId: 150079,
      forecastSpotId: 409,
      offshore: { start: 35, end: 195 },
    },
    {
      title: 'Crissy Field',
      spotId: 411,
      offshore: { start: 90, end: 250 },
    },
  ],
}
