import { Area } from '../components/App'

export const hoodRiver: Area = {
  key: 'hoodRiver',
  name: 'Hood River',
  mapSettings: {
    coordinates: {
      lat: 45.6908608,
      lng: -121.5790773,
    },
    zoom: 20,
    googleMapsZoom: 11,
  },
  forecastId: '14',
  spots: [
    {
      title: 'Event Site',
      spotId: 150692,
    },
    {
      title: 'Stevenson',
      spotId: 116431,
    },
    {
      title: 'Rooster Rock',
      spotId: 86867,
    },
    {
      title: 'Swell City',
      spotId: 440,
    },
    {
      title: 'Lyle',
      spotId: 83135,
    },
    {
      title: 'Rufus',
      spotId: 441,
    },
  ],
}
