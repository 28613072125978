import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AutocompleteResult } from '../../../backend/src/gusty'
import * as api from '../api'
import { SpotWithData } from '../reducer'
import { MapSettings } from './App'
import SpotSection from './SpotSection'
import SpotsMap from './SpotsMap'

type SpotModalProps = {
  result?: AutocompleteResult
  open: boolean
  onClose: () => void
}

export function SpotModal({ result, open, onClose }: SpotModalProps): JSX.Element | null {
  const [data, setData] = useState<SpotWithData | null>(null)
  const [mapSettings, setMapSettings] = useState<MapSettings | null>(null)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    if (!token || !result || !result.id) {
      setData(null)
      return
    }

    const spotId = result.id
    api
      .getLiveWind({ token, spotIds: [Number(result.id)] })
      .then((liveWinds) => {
        const newData: SpotWithData = {
          title: result.name,
          spotId: Number(result.id),
          liveWind: liveWinds[spotId],
        }
        setData(newData)
      })
      .catch((err) => console.error(err))
  }, [result, token])

  useEffect(() => {
    if (!result) {
      setMapSettings(null)
      return
    }
    setMapSettings({
      coordinates: result.coordinates,
      zoom: 14,
      googleMapsZoom: 13,
    })
  }, [result])

  if (!result) {
    return null
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h4">{result.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {data && <SpotSection includeTitle={false} xs={12} spotWithData={data} />}
          </Grid>
          <Grid item xs={12} md={result.id ? 6 : 12}>
            {mapSettings && <SpotsMap height={400} mapSettings={mapSettings} allowModal={false} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
