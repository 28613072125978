import { Area } from '../components/App'

export const ventana: Area = {
  key: 'ventana',
  name: 'La Ventana',
  mapSettings: {
    coordinates: {
      lat: 24.0487596,
      lng: -109.9873786,
    },
    zoom: 20,
    googleMapsZoom: 15,
  },
  forecastId: '56',
  spots: [
    {
      title: 'La Ventana 1',
      spotId: 1326,
    },
    {
      title: 'La Ventana 2',
      spotId: 183476,
    },
    {
      title: 'La Ventana Campground',
      spotId: 166441,
    },
    {
      title: 'Rasta Beach',
      spotId: 199226,
    },
    {
      title: 'Baja Weather Station',
      spotId: 191565,
    },
  ],
}
