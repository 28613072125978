import { faMapMarkerAlt, faWind } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchIcon from '@mui/icons-material/Search'
import {
  AppBar,
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Popper,
  PopperProps,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { AutocompleteResult } from '../../../backend/src/gusty'
import * as api from '../api'
import { bayArea } from '../areas/bayArea'
import { hoodRiver } from '../areas/hoodRiver'
import { southTexas } from '../areas/southTexas'
import { ventana } from '../areas/ventana'
import { Area } from './App'
import { SpotModal } from './SpotModal'

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    marginLeft: theme.spacing(1),
    flex: 1,
    maxWidth: 400,
    '& .MuiInputBase-root': {
      paddingRight: '8px !important',
    },
  },
  chips: {
    display: 'inline-flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

type TopBarProps = {
  area: Area
}

function CustomPopper(props: PopperProps) {
  return <Popper {...props} style={{ width: '400px !important' }} placement="bottom-start" />
}

export default function TopBar({ area }: TopBarProps): JSX.Element {
  const classes = useStyles()
  const navigate = useNavigate()
  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteResult[]>([])
  const [selectedResult, setSelectedResult] = useState<AutocompleteResult | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const token = useSelector((state) => state.token)

  const onAreaClick = (area: string) => {
    navigate(`/?area=${area}`)
  }

  useDebounce(
    () => {
      if (!token) {
        return
      }
      if (inputValue.length < 2) {
        setSelectedResult(null)
        setAutocompleteResults([])
        return
      }

      api
        .autocomplete({ input: inputValue, token })
        .then(setAutocompleteResults)
        .catch((err) => console.error(err))
    },
    200,
    [inputValue]
  )

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      style={{ backgroundColor: 'rgb(248, 249, 250)' }}
    >
      <Toolbar>
        <FormControl size="small" style={{ flexShrink: 0 }}>
          <Select
            variant="outlined"
            value={area.key}
            onChange={(ev) => onAreaClick(ev.target.value)}
          >
            <MenuItem value={bayArea.key}>Bay Area</MenuItem>
            <MenuItem value={hoodRiver.key}>Hood River</MenuItem>
            <MenuItem value={ventana.key}>La Ventana</MenuItem>
            <MenuItem value={southTexas.key}>South Texas</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          className={classes.autocomplete}
          popupIcon={null}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          filterOptions={(x) => x}
          options={autocompleteResults}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={selectedResult}
          onChange={(event, newValue) => {
            setSelectedResult(newValue)
            setIsModalOpen(newValue !== null)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Search…"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={`${option.type}-${option.name}-${option.id ?? ''}`}>
                <Grid container alignItems="center">
                  <Grid item>
                    <FontAwesomeIcon
                      icon={option.type === 'place' ? faMapMarkerAlt : faWind}
                      className={classes.icon}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">{option.name}</Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
          PopperComponent={CustomPopper}
        />
      </Toolbar>
      <SpotModal
        result={selectedResult ?? undefined}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </AppBar>
  )
}
