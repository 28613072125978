import { Chip, CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpotWithData } from '../reducer'
import { fixedSpeed, getColorForWindSpeed, getTextColorForWindSpeed } from '../utils'

type LiveWindSummaryProps = {
  spotsWithData: SpotWithData[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  update: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updateText: {
    marginLeft: `${theme.spacing(1)} !important`,
    flexShrink: 0,
  },
  chips: {
    // marginTop: theme.spacing(1),
  },
}))

export function LiveWindSummary({ spotsWithData }: LiveWindSummaryProps) {
  const classes = useStyles()
  const [, setStateChangeToForceTimerRerender] = useState(Date.now())
  const isLoadingSpotsWithData = useSelector((state) => state.isLoadingSpotsWithData)
  const lastUpdateTimeString = useSelector((state) => state.lastUpdateTime)
  const lastUpdateTime = lastUpdateTimeString ? DateTime.fromISO(lastUpdateTimeString) : null

  useEffect(() => {
    const interval = setInterval(() => {
      setStateChangeToForceTimerRerender(Date.now())
    }, 1000)
    return () => clearInterval(interval)
  })

  const updateTimeSecondsFromNow = lastUpdateTime
    ? DateTime.now().diff(lastUpdateTime).as('seconds')
    : 0
  const updateText = isLoadingSpotsWithData
    ? 'Updating...'
    : `Updated ${updateTimeSecondsFromNow.toFixed(0)}s ago`

  const orderedSpots = _.orderBy(spotsWithData, (spot) => spot.liveWind.current.average, 'desc')
  const chips = _.map(orderedSpots, (spot) => {
    const backgroundColor = getColorForWindSpeed(spot.liveWind.current.average)
    const textColor = getTextColorForWindSpeed(spot.liveWind.current.average)

    return (
      <Chip
        key={spot.spotId}
        size="small"
        label={
          <span>
            {spot.title}: {fixedSpeed(spot.liveWind.current.average)}kts
          </span>
        }
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          marginLeft: 8,
          marginBottom: 8,
        }}
      />
    )
  })

  return (
    <div className={classes.root}>
      <div className={classes.chips}>{chips}</div>
      <div className={classes.update}>
        {isLoadingSpotsWithData && <CircularProgress className={classes.progress} size={20} />}
        <Typography
          variant="body2"
          component="span"
          color="textSecondary"
          className={classes.updateText}
        >
          <i>{updateText}</i>
        </Typography>
      </div>
    </div>
  )
}
