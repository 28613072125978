import _ from 'lodash'

export const strength = {
  weak: {
    description: 'Weak',
    color: '#81ecec',
    speeds: [0, 8],
  },
  foilable: {
    description: 'Foilable',
    color: '#55efc4',
    speeds: [9, 12],
  },
  light: {
    description: 'Light',
    color: '#ffeaa7',
    speeds: [13, 15],
  },
  fair: {
    description: 'Fair',
    color: '#fdcb6e',
    speeds: [16, 18],
  },
  solid: {
    description: 'Solid',
    color: '#fab1a0',
    speeds: [19, 22],
  },
  strong: {
    description: 'Strong',
    color: '#ff7675',
    speeds: [23, 27],
  },
  veryStrong: {
    description: 'Very strong',
    color: '#d63031',
    speeds: [28, 50],
  },
}

export function getColorForWindSpeed(speed: number): string {
  const found = _.find(strength, (value) => _.inRange(speed, value.speeds[0], value.speeds[1] + 1))
  if (!found) {
    return strength.veryStrong.color
  }
  return found.color
}

export function getColorForWindStrength(key: keyof typeof strength): string {
  return strength[key].color
}

export function getTextColorForWindSpeed(speed: number): string {
  return 'black'
}

export function getTextColorForWindStrength(key: keyof typeof strength): string {
  return 'black'
}

export function fixedSpeed(speed: number): string {
  return Math.round(speed).toFixed(0)
}
