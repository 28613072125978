import { faArrowUp, faCamera, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridProps,
  Typography,
} from '@mui/material'
import { orange } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { DailyForecastSpot } from '../../../backend/src/gusty'
import { SpotWithData } from '../reducer'
import * as utils from '../utils'
import SpotForecast from './SpotForecast'
import SpotLiveWind from './SpotLiveWind'

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: `${orange[500]} !important`,
    marginRight: theme.spacing(1),
    '@media (min-width: 576px)': {
      marginRight: theme.spacing(2),
    },
  },
  lullGust: {
    marginLeft: theme.spacing(2),
  },
  speedValue: {
    marginLeft: theme.spacing(1),
  },
  label: {
    fontWeight: 'normal',
  },
  forecastTitleContainer: {
    verticalAlign: 'bottom',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
}))

export type SpotSectionProps = GridProps & {
  spotWithData: SpotWithData
  forecastWrapper?: ForecastWrapper
  includeTitle?: boolean
}

export type ForecastWrapper = {
  forecast: DailyForecastSpot
  forecastedDay: string
  forecastCreatedAt: DateTime
  timeZone: string
  note?: string
}

function between(angle: number, start: number, end: number): boolean {
  const mod = (x: number) => ((x % 360) + 360) % 360
  const modAngle = mod(angle)
  const modStart = mod(start)
  const modEnd = mod(end)

  if (modStart < modEnd) {
    return modStart <= modAngle && modAngle <= modEnd
  }
  return modStart <= modAngle || modAngle <= modEnd
}

export default function SpotSection({
  spotWithData,
  forecastWrapper,
  includeTitle = true,
  ...rest
}: SpotSectionProps): JSX.Element {
  const classes = useStyles()
  const rotation = spotWithData.liveWind.current.direction - 180
  const [isWebcamModalOpen, setIsWebcamModalOpen] = useState(false)
  const [refreshTime, setRefreshTime] = useState(Date.now())
  const [isLiveWindModalOpen, setIsLiveWindModalOpen] = useState(false)

  const direction = spotWithData.liveWind.current.direction
  const isOffshore =
    spotWithData.offshore &&
    between(spotWithData.offshore.start, spotWithData.offshore.end, direction)

  return (
    <Grid item {...rest}>
      {includeTitle && (
        <div>
          <Typography variant="h4" style={{ display: 'inline-block' }}>
            {spotWithData.title}
          </Typography>

          <span style={{ marginLeft: '16px' }}>
            {spotWithData.webcamUrl && (
              <FontAwesomeIcon
                icon={faCamera}
                style={{ color: '#7f8c8d', cursor: 'pointer' }}
                onClick={() => {
                  setIsWebcamModalOpen(true)
                  setRefreshTime(Date.now())
                }}
              />
            )}

            {spotWithData.liveWindImageUrl && (
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ color: '#7f8c8d', marginLeft: '12px', cursor: 'pointer' }}
                onClick={() => {
                  setIsLiveWindModalOpen(true)
                  setRefreshTime(Date.now())
                }}
              />
            )}
          </span>
        </div>
      )}

      <Typography variant="h6">
        {isOffshore && <Chip size="small" className={classes.chip} label="Offshore" />}
        {spotWithData.liveWind.isGusty && (
          <Chip size="small" className={classes.chip} label="Gusty" />
        )}
        <span className={classes.label}>Avg</span>
        <span className={classes.speedValue}>
          {utils.fixedSpeed(spotWithData.liveWind.current.average)}{' '}
          <FontAwesomeIcon icon={faArrowUp} style={{ transform: `rotate(${rotation}deg` }} />
        </span>

        <span className={clsx([classes.label, classes.lullGust])}>Lull/Gust</span>
        <span className={classes.speedValue}>
          {utils.fixedSpeed(spotWithData.liveWind.current.lull)}/
          {utils.fixedSpeed(spotWithData.liveWind.current.gust)}
        </span>
      </Typography>

      <div style={{ marginTop: '20px' }}>
        <div
          style={{
            position: 'relative',
            height: '285px',
            marginLeft: '-6px',
            marginRight: '-6px',
          }}
        >
          <SpotLiveWind liveWindData={spotWithData.liveWind} />
        </div>
      </div>

      {forecastWrapper && (
        <div>
          <div className={classes.forecastTitleContainer}>
            <Typography variant="body1">
              Forecast for{' '}
              {DateTime.fromISO(forecastWrapper.forecastedDay)
                .setZone(forecastWrapper.timeZone)
                .toLocaleString({ ...DateTime.DATE_HUGE, year: undefined })}
            </Typography>
            <Typography variant="caption" style={{ fontStyle: 'italic' }}>
              Updated{' '}
              {forecastWrapper.forecastCreatedAt
                .setZone(forecastWrapper.timeZone)
                .toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
          </div>
          <SpotForecast forecast={forecastWrapper.forecast} />
          {forecastWrapper.forecast.note && (
            <div>
              <Typography variant="caption">Note: {forecastWrapper.forecast.note}</Typography>
            </div>
          )}
        </div>
      )}

      {/* Webcam */}
      <Dialog open={isWebcamModalOpen} onClose={() => setIsWebcamModalOpen(false)}>
        <DialogTitle>{spotWithData.title}</DialogTitle>
        <DialogContent>
          {spotWithData.webcamUrl && (
            <img
              style={{ width: '100%' }}
              src={`${spotWithData.webcamUrl}?t=${refreshTime}`}
              alt="Webcam"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsWebcamModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Live Wind */}
      <Dialog open={isLiveWindModalOpen} onClose={() => setIsLiveWindModalOpen(false)}>
        <DialogTitle>{spotWithData.title}</DialogTitle>
        <DialogContent>
          {spotWithData.liveWindImageUrl && (
            <img
              style={{ width: '100%' }}
              src={`${spotWithData.liveWindImageUrl}?t=${refreshTime}`}
              alt="Live Wind"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLiveWindModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
