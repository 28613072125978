import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import * as utils from '../utils'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'none',
    '@media (min-width: 576px)': {
      display: 'block',
    },
  },
  footerTable: {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
  },
  footerTableCol: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

export default function Footer(): JSX.Element {
  const classes = useStyles()
  const cols = _.map(utils.strength, (value, key) => {
    return (
      <td className={classes.footerTableCol} style={{ backgroundColor: value.color }} key={key}>
        <Typography variant="body2">
          {value.description}
          <br />
          {_.first(value.speeds)} - {_.last(value.speeds)}
          <span className="unit"> kts</span>
        </Typography>
      </td>
    )
  })

  return (
    <div className={classes.footerContainer}>
      <table className={classes.footerTable}>
        <tbody>
          <tr>{cols}</tr>
        </tbody>
      </table>
    </div>
  )
}
