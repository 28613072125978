import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { AiForecast, DailyForecast, ExtendedForecast, LiveWind } from '../../backend/src/gusty'
import * as api from './api'
import { Area } from './components/App'

export interface SpotWithData {
  title: string
  spotId: number
  forecastSpotId?: number
  liveWindImageUrl?: string
  webcamUrl?: string
  offshore?: { start: number; end: number }
  liveWind: LiveWind
}

export interface GustyState {
  token: string | null
  lastUpdateTime: string | null
  loadCount: number
  isLoadingSpotsWithData: boolean
  spotsWithData: SpotWithData[] | null
  dailyForecast: DailyForecast | null
  extendedForecast: ExtendedForecast | null
  isLoadingAiForecast: boolean
  aiForecast: AiForecast | null
}

export const defaultState: GustyState = {
  token: localStorage.getItem('token'),
  lastUpdateTime: null,
  loadCount: 0,
  isLoadingSpotsWithData: false,
  spotsWithData: null,
  dailyForecast: null,
  extendedForecast: null,
  aiForecast: null,
  isLoadingAiForecast: false,
}

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends GustyState {}
}

export const loadDailyForecast = createAsyncThunk(
  'dailyForecast',
  async ({ forecastId, token }: { forecastId: string; token: string }) => {
    return api.getDailyForecast({ forecastId, token })
  }
)

export const loadExtendedForecast = createAsyncThunk(
  'extendedForecast',
  async ({ forecastId, token }: { forecastId: string; token: string }) => {
    return api.getExtendedForecast({ forecastId, token })
  }
)

export const loadAiForecast = createAsyncThunk(
  'aiForecast',
  async ({
    forecastId,
    favoriteSpotIds,
    token,
  }: {
    forecastId: string
    favoriteSpotIds: number[]
    token: string
  }) => {
    return api.getAiForecast({ forecastId, favoriteSpotIds, token })
  }
)

export const loadSpots = createAsyncThunk(
  'loadSpots',
  async ({ area, token }: { area: Area; token: string }) => {
    const spotIds = area.spots.map((spot) => spot.spotId)
    const liveWind = await api.getLiveWind({ spotIds, token })
    return area.spots.map((spot) => ({
      ...spot,
      liveWind: liveWind[spot.spotId],
    }))
  }
)

export const login = createAsyncThunk(
  'login',
  async ({ username, password }: { username: string; password: string }) => {
    return api.login(username, password)
  }
)

export const slice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    resetCache: (state) => ({ ...defaultState, token: state.token }),
    invalidToken: (state) => {
      localStorage.removeItem('token')
      return { ...state, token: null }
    },
  },
  extraReducers: (builder) => {
    // Load Spots
    builder.addCase(loadSpots.pending, (state) => {
      state.isLoadingSpotsWithData = true
    })
    builder.addCase(loadSpots.fulfilled, (state, action) => {
      state.loadCount += 1
      state.isLoadingSpotsWithData = false
      state.spotsWithData = action.payload
      state.lastUpdateTime = DateTime.now().toISO()
    })
    builder.addCase(loadSpots.rejected, (state, action) => {
      state.isLoadingSpotsWithData = false
    })

    // Load Daily Forecast
    builder.addCase(loadDailyForecast.pending, (state) => state)
    builder.addCase(loadDailyForecast.fulfilled, (state, action) => {
      state.dailyForecast = action.payload
    })
    builder.addCase(loadDailyForecast.rejected, (state) => state)

    // Load Extended Forecast
    builder.addCase(loadExtendedForecast.pending, (state) => state)
    builder.addCase(loadExtendedForecast.fulfilled, (state, action) => {
      state.extendedForecast = action.payload
    })
    builder.addCase(loadExtendedForecast.rejected, (state) => state)

    // Load AI Forecast
    builder.addCase(loadAiForecast.pending, (state) => {
      state.isLoadingAiForecast = true
      return state
    })
    builder.addCase(loadAiForecast.fulfilled, (state, action) => {
      state.aiForecast = action.payload
      state.isLoadingAiForecast = false
    })
    builder.addCase(loadAiForecast.rejected, (state) => {
      state.isLoadingAiForecast = false
    })

    // Login
    builder.addCase(login.pending, (state) => state)
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token
      localStorage.setItem('token', action.payload.token)
    })
    builder.addCase(login.rejected, (state) => state)
  },
})

export const { resetCache, invalidToken } = slice.actions
export default slice.reducer
