import { createTheme, ThemeProvider } from '@mui/material'
import { configureStore } from '@reduxjs/toolkit'
import { createRoot } from 'react-dom/client'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import { Login } from './components/Login'
import reducer from './reducer'

export const store = configureStore({ reducer })
const domContainer = document.querySelector('#root')
if (!domContainer) {
  throw new Error('No root element found')
}

const root = createRoot(domContainer)
const theme = createTheme()

function OuterApp() {
  const token = useSelector((state) => state.token)
  if (!token) {
    return <Login />
  }
  return <App />
}

const provider = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <OuterApp />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
)
root.render(provider)
