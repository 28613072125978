import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
} from 'chart.js'
import 'chartjs-adapter-luxon'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import { LiveWind } from '../../../backend/src/gusty'

ChartJS.register(LineElement, PointElement, LinearScale, Title, TimeScale, Filler)

interface SpotLiveWindProps {
  liveWindData: LiveWind
}

export default function SpotLiveWind({ liveWindData }: SpotLiveWindProps): JSX.Element {
  const points = liveWindData.points
  const maxGust = _.max(_.map(points, 'gust')) ?? 0
  const max = Math.max(maxGust, 25)
  const nearestFive = Math.ceil(max / 5) * 5

  const data = {
    labels: _.map(points, (point) => {
      return DateTime.fromISO(point.time).setZone(liveWindData.timeZone)
    }),
    datasets: [
      {
        label: 'lull',
        data: _.map(points, 'lull'),
        borderWidth: 1,
        borderColor: '#00b894',
        tension: 0.4,
      },
      {
        label: 'average',
        data: _.map(points, 'average'),
        borderWidth: 2,
        borderColor: '#0984e3',
        backgroundColor: '#74b9ff',
        fill: true,
        tension: 0.4,
      },
      {
        label: 'gust',
        data: _.map(points, 'gust'),
        borderWidth: 1,
        borderColor: '#ff7675',
        tension: 0.4,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    animation: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
        },
        adapters: {
          date: {
            zone: liveWindData.timeZone,
          },
        },
      },
      y: {
        min: 0,
        max: nearestFive,
        beginAtZero: true,
      },
    },
  } as const

  return <Line data={data} options={options} />
}
